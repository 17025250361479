import { GridActionsCellItem, GridColDef, getGridStringOperators, GridFilterInputValue, GRID_AGGREGATION_ROOT_FOOTER_ROW_ID } from '@mui/x-data-grid-premium';
import React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import * as Icon from "react-feather";

export const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const percentFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});
export default class AdsetViewHelper {
    constructor() {
    }

    toggleYesterDayResults(visible = false, columns) {
        return columns.map(col => {
            if (col.dataField.includes(" -1 Day")) {
                col.hidden = !visible;
                return col;
            } else {
                return col;
            }
        });

    }

    checkboxFormatter(row, { toggleChecked }) {
        return (<Form.Check
            type="switch"
            name="isActive" id="isActive"
            value={row.isActive}
            onChange={event => toggleChecked(event.target.checked, row)}
            checked={row.isActive}
        />)
    }

    setGridColsFull(revertAdsetChanges, toggleIsVisible, adsetNameWidth, toggleFilterState, isCampaignView) {
        const columns = [
            {
                field: "isActive", headerName: "Active", sortable: false, editable: true,
                headerClassName: 'isActiveCol shortView',
                cellClassName: 'isActiveCol shortView',
                headerAlign: 'center',
                align: 'center',
                width: 55,
                resizable: false,
                renderCell: (params) => params.id === GRID_AGGREGATION_ROOT_FOOTER_ROW_ID ? <></> : this.checkboxFormatter(params.row, { toggleChecked: toggleIsVisible }),
                renderHeaderFilter: () => null,
                aggregable: false,
            },
            {
                field: "adsetName",
                renderHeader: (params) => (
                    <>
                        {'Name'}
                        <GridActionsCellItem
                            label="Filter"
                            icon={<Icon.Filter size={18}/>}
                            onClick={() => toggleFilterState()}
                            title="Filter"
                        />
                    </>
                ),
                headerClassName: 'adsetNameCol shortView',
                cellClassName: 'adsetNameCol shortView',
                filterOperators: [
                    ...getGridStringOperators(),
                    {
                        value: "noContain",
                        getApplyFilterFn: (
                            filterItem
                        ) => {
                            if (
                                !filterItem.field ||
                                !filterItem.value ||
                                !filterItem.operator
                            ) {
                                return null;
                            }

                            const filterRegex = new RegExp(filterItem.value, "i");
                            return (value) => {
                                const rowValue = value;
                                return !filterRegex.test(rowValue?.toString() || "");
                            };
                        },
                        InputComponent: GridFilterInputValue
                    }
                ],
                width: adsetNameWidth,
                resizable: true,
                renderCell: (params) => (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {params.value}
                            </Tooltip>
                        }
                    >
                        <div>{params.value}</div>
                    </OverlayTrigger>
                ),
                aggregable: false,
            },
            {
                field: "bidAmount", headerName: "Bid", editable: !isCampaignView,
                headerClassName: 'bidCol shortView',
                cellClassName: 'bidCol shortView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                width: 80,
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null,
                aggregable: false,
            },
            {
                field: "Spend Today",
                headerName: "Spend Today",
                sortable: true,
                headerClassName: 'sfDataHeader',
                cellClassName: 'sfDataCell shortView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                availableAggregationFunctions: ['max', 'min', 'sum', 'avg', 'size'],
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null

            },
            {
                field: "ROI - Today",
                headerName: "ROI",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                headerAlign: 'center',
                align: 'center',
                type: 'number',
                availableAggregationFunctions:['roiAggregation'],
                resizable: false,
                valueFormatter: (value) => { return ((value !== null && value !== undefined) ? percentFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "CPA Today",
                headerName: "CPA Today",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "Spend -1 hour",
                headerName: "Spend -1 Hour",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                type: 'number',
                availableAggregationFunctions: ['max', 'min', 'sum', 'avg', 'size'],
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null

            },
            {
                field: "ROI -1 hour",
                headerName: "ROI -1 Hour",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                headerAlign: 'center',
                align: 'center',
                type: 'number',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? percentFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "CPA -1 hour",
                headerName: "CPA -1 Hour",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "ROI -1 Day",
                headerName: "ROI -1 Day",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                headerAlign: 'center',
                align: 'center',
                type: 'number',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? percentFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "CPA -1 Day",
                headerName: "CPA -1 Day",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "ROI -1 Day Now",
                headerName: "ROI -1 Day Now",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                headerAlign: 'center',
                align: 'center',
                type: 'number',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? percentFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "CPA -1 Day Now",
                headerName: "CPA -1 Day Now",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "Spend -1 Day -1 hour",
                headerName: "Spend -1 Day -1 Hour",
                sort: false,
                editable: false,
                type: 'number',
                availableAggregationFunctions: ['max', 'min', 'sum', 'avg', 'size'],
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "ROI -1 Day -1 hour",
                headerName: "ROI -1 Day -1 Hour",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                headerAlign: 'center',
                align: 'center',
                type: 'number',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? percentFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "ROI -7 Days",
                headerName: "ROI -7 Days",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                headerAlign: 'center',
                align: 'center',
                type: 'number',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? percentFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "CPA-BE Today",
                headerName: "CPA-BE Today",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "Spend -7 Days -1 hour",
                headerName: "Spend -7 Days -1 Hour",
                sort: false,
                editable: false,
                type: 'number',
                availableAggregationFunctions: ['max', 'min', 'sum', 'avg', 'size'],
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "ROI -7 Days -1 hour",
                headerName: "ROI -7 Days -1 Hour",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                headerAlign: 'center',
                align: 'center',
                type: 'number',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? percentFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null
            },

            {
                field: "budget",
                headerName: "Budget",
                headerClassName: 'budgetCol shortView',
                cellClassName: 'budgetCol shortView',
                type: 'number',
                availableAggregationFunctions: ['max', 'min', 'sum', 'avg', 'size'],
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null
            },

            {
                field: "dailyBudget",
                headerName: "Daily Budget",
                editable:true,
                headerClassName: 'dailyBudgetCol shortView',
                cellClassName: 'dailyBudgetCol shortView',
                type: 'number',
                availableAggregationFunctions: ['max', 'min', 'sum', 'avg', 'size'],
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null
            },


            {
                field: "budgetRemaing",
                headerName: "Budget Remaining",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader shortView',
                cellClassName: 'sfDataCell shortView',
                type: 'number',
                availableAggregationFunctions: ['max', 'min', 'sum', 'avg', 'size'],
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return ((value !== undefined || value !== null) ? currencyFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null

            },
            {
                field: "Sales BE Today",
                headerName: "Sales BE Today",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader',
                cellClassName: 'sfDataCell shortView',
                type: 'number',
                availableAggregationFunctions: ['max', 'min', 'sum', 'avg', 'size'],
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? Number(value) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "Conv Today",
                headerName: "Conv Today",
                sort: false,
                editable: false,
                cellClassName: 'sfDataCell fullView',
                type: 'number',
                availableAggregationFunctions: ['max', 'min', 'sum', 'avg', 'size'],
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? Number(value) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "NCR Today",
                headerName: "NCR Today",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueGetter: (value, row) => {
                    return row['Sales BE Today'] === 0 ? "null" : value;
                },
                valueFormatter: (value) => { return (value !== undefined ? (value === "null" ? NaN : percentFormatter.format(Number(value))) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "Conv -1 hour",
                headerName: "Conv -1 Hour",
                sort: false,
                editable: false,
                headerAlign: 'center',
                type: 'number',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? Number(value) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "cpm",
                headerName: "CPM",
                sort: false,
                editable: false,
                width: 70,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "ctr",
                headerName: "CTR",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                headerAlign: 'center',
                align: 'center',
                type: 'number',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? percentFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "AOV Today",
                headerName: "AOV Today",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null

            },
            {
                field: "AOV -1 Day",
                headerName: "AOV -1 Day",
                sort: false,
                editable: false,
                hidden: true,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                type: 'number',
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null

            },
            {
                field: "Revenue BE Today",
                headerName: "Revenue BE Today",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader shortView',
                cellClassName: 'sfDataCell shortView',
                type: 'number',
                availableAggregationFunctions: ['max', 'min', 'sum', 'avg', 'size'],
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null

            },
            {
                field: "Spend -1 Day now",
                headerName: "Spend -1 Day Now",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader',
                cellClassName: 'sfDataCell fullView',
                type: 'number',
                availableAggregationFunctions: ['max', 'min', 'sum', 'avg', 'size'],
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null

            },

            {
                field: "Sales BE -1 Day",
                headerName: "Sales BE -1 Day",
                sort: false,
                editable: false,
                hidden: true,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                type: 'number',
                availableAggregationFunctions: ['max', 'min', 'sum', 'avg', 'size'],
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? Number(value) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "Revenue BE -1 Day",
                headerName: "Revenue BE -1 Day",
                sort: false,
                editable: false,
                hidden: true,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                type: 'number',
                availableAggregationFunctions: ['max', 'min', 'sum', 'avg', 'size'],
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? currencyFormatter.format(Number(value)) : "N/A") },
                renderHeaderFilter: () => null

            },
            {
                field: "Conv -1 Day",
                headerName: "Conv -1 Day",
                sort: false,
                editable: false,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                type: 'number',
                availableAggregationFunctions: ['max', 'min', 'sum', 'avg', 'size'],
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? Number(value) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "initiate_checkout_today",
                headerName: "Initiate Checkout Today",
                sort: false,
                editable: false,
                hidden: true,
                headerClassName: 'sfDataHeader fullView',
                cellClassName: 'sfDataCell fullView',
                type: 'number',
                availableAggregationFunctions: ['max', 'min', 'sum', 'avg', 'size'],
                headerAlign: 'center',
                align: 'center',
                resizable: false,
                valueFormatter: (value) => { return (value !== undefined ? Number(value) : "N/A") },
                renderHeaderFilter: () => null
            },
            {
                field: "revert",
                type: "actions",
                aggregable: false,
                headerClassName: 'revertButtonCol shortView',
                cellClassName: 'revertButtonCol shortView',
                width: 35,
                resizable: false,
                getActions: (params) =>
                    params.id === GRID_AGGREGATION_ROOT_FOOTER_ROW_ID ? [] : [<GridActionsCellItem
                        label="Revert"
                        icon={<Icon.RotateCcw />}
                        onClick={() => revertAdsetChanges(params.row)}
                        title="Revert"
                    />],
                renderHeaderFilter: () => null
            }
        ]
        return columns;
    }




}