import {BpCheckedIcon, BpIcon} from "../pages/facebook-tools/components/adsetDataView/adsetDataConstants";
import Radio from '@mui/material/Radio';

export function calculateDaysRemaining(adsetWeekdays, adsetEndTime){
    const msInDay = 1000 * 60 * 60 * 24;
    const msInWeek = msInDay * 7;
    const FullWeekArrayLength = 7;
    let now = new Date();
    let endDate = new Date(adsetEndTime);
    let adsetActiveDaysLeftCounter = 0;
    let adsetWeekdaysArray = getWeekdaysSchedulingArray(adsetWeekdays);
    if(adsetWeekdaysArray.length == 7){//no need to make any special calculation
        adsetActiveDaysLeftCounter = Math.ceil(Math.abs(endDate - now) / msInDay);
    }else{
        let fullWeeksBetweenDates = Math.floor(Math.abs(endDate - now) / msInWeek);//we count the number of full weeks within that period,
        let activeDaysForFullWeeks = adsetWeekdaysArray.length * fullWeeksBetweenDates;// and we can multiply that by the number of days in a week the adset is active on
        adsetActiveDaysLeftCounter += activeDaysForFullWeeks;
        endDate.setDate(endDate.getDate() - (FullWeekArrayLength * fullWeeksBetweenDates));//now we can just calculate for the remaining days(up to 6)

        for (let d = now; d <= endDate; d.setDate(d.getDate() + 1)) {
            if(adsetWeekdaysArray.includes(d.getDay())){
                adsetActiveDaysLeftCounter++;
            }
        }
    }

    return adsetActiveDaysLeftCounter;
}

export function isTestAdset(adsetName){
    return adsetName.includes("-test_");
}

export function isDailyAdset(adsetWeekdays){
    return adsetWeekdays ? JSON.parse(adsetWeekdays) == null : true;
}

export function shouldAdsetRunToday(adsetWeekdays){
        
    let adsetWeekdaysArray = getWeekdaysSchedulingArray(adsetWeekdays);
    return adsetWeekdaysArray.includes(new Date().getDay());
}

export function getStatusByEnum(adsetStatus) {
    if (adsetStatus !== 'PAUSED') {
        return true;
    } else {
        return false;
    }
}

function getWeekdaysSchedulingArray(adsetWeekdays){
    const weeklyArray = [0, 1, 2, 3, 4, 5, 6];
    return JSON.parse(adsetWeekdays) == null ? weeklyArray : JSON.parse(adsetWeekdays);
}

// Inspired by blueprintjs
export function BpRadio(props) {
    return (
    <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
    />
    );
}

export function fillAdsetCalculatedFields(adset){
    let commission = adset["commission"];
    adset["ROI - Today"] = adset["Spend Today"] > 0 ? adset["Revenue BE Today"] / adset["Spend Today"] * commission : 0;
    adset["ROI -1 Day"] = adset["spend_yesterday"] > 0 ? adset["Revenue BE -1 Day"] / adset["spend_yesterday"] * commission : 0;
    adset["ROI -1 hour"] = adset["Spend -1 hour"] > 0 ? adset["Revenue BE Today"] / adset["Spend -1 hour"] * commission : 0;
    adset["ROI -1 Day -1 hour"] = adset["Spend -1 Day -1 hour"] > 0 ? adset["revenue_be_yesterday_now"] / adset["Spend -1 Day -1 hour"] * commission : 0;
    adset["ROI -7 Days -1 hour"] = adset["Spend -7 Days -1 hour"] > 0 ? adset["revenue_be_7day_now"] / adset["Spend -7 Days -1 hour"] * commission : 0;
    adset["ROI -1 Day Now"] = adset["Spend -1 Day now"] > 0 ? adset["revenue_be_yesterday_now"] / adset["Spend -1 Day now"] * commission : 0;
    adset["ROI -7 Days"] = adset["spend_7day"] > 0 ? adset["revenue_be_7day"] / adset["spend_7day"] * commission : 0;
    adset["CPA Today"] = adset["Conv Today"] > 0 ? adset["Spend Today"] / adset["Conv Today"] : 0;
    adset["CPA-BE Today"] = adset["Sales BE Today"] > 0 ? adset["Spend Today"] / adset["Sales BE Today"] : 0;
    adset["CPA -1 Day"] = adset["Conv -1 Day"] > 0 ? adset["spend_yesterday"] / adset["Conv -1 Day"] : 0;
    adset["CPA -1 hour"] = adset["Conv -1 hour"] > 0 ? adset["Spend -1 hour"] / adset["Conv -1 hour"] : 0;
    adset["CPA -1 Day Now"] = adset["Conv -1 Day Now"] > 0 ? adset["Spend -1 Day now"] / adset["Conv -1 Day Now"] : 0;
    adset["AOV Today"] = adset["Sales BE Today"] > 0 ? adset["Revenue BE Today"] / adset["Sales BE Today"] : 0;
    adset["AOV -1 Day"] = adset["Sales BE -1 Day"] > 0 ? adset["Revenue BE -1 Day"] / adset["Sales BE -1 Day"] : 0;
    adset["ctr"] = adset["impressions_today"] > 0 ? adset["clicks_today"] / adset["impressions_today"] : 0;
    adset["cpm"] = adset["impressions_today"] > 0 ? adset["Spend Today"] / adset["impressions_today"] * 1000 : 0;
    adset["NCR Today"] = adset["Sales BE Today"] > 0 ? adset["new_customers_today"] / adset["Sales BE Today"] : 0;

    //log all the fields  for campaign budget level
    if(adset.budget_level === "Campaign"){
        console.log("|#|#|#|#|#|#|#|#|#|#|#|#|#|#|#|#|#|#|#|#|#|#|#|#|#|#|#|#|")
        // log 3 empty lines
        console.log("\n\n\n");
        console.log("Sales BE Today : " + adset["Sales BE Today"]);
        // Object.keys(adset).forEach((key) => {
        //     console.log(key + " : " + adset[key]);
        // });
    }       

    return adset;   
}